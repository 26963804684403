<template>
  <b-card>
    <b-row class="table-filter">
      <!-- filter date from -->
      <b-col cols="2">
        <b-form-group
          id="input-from-date"
          label="Từ Ngày"
          label-for="from-date"
        >
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-to-date" label="Đến Ngày" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group
          id="input-code-filter"
          label="Mã Giới Thiệu"
          label-for="code-filter"
        >
          <b-form-input
            id="code-filter"
            v-model="invite_code"
            type="text"
            placeholder="Mã Giới Thiệu ..."
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group
          id="input-root-filter"
          label="Giới Thiệu Bởi"
          label-for="root-filter"
        >
          <b-form-input
            id="root-filter"
            v-model="root_inviter_code"
            type="text"
            placeholder="Giới Thiệu Bởi ..."
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button
          block
          variant="success"
          class="search-btn"
          @click="handleSearch"
          >TÌM KIẾM</b-button
        >
      </b-col>

      <b-col cols="2">
        <b-button
          block
          variant="primary"
          class="search-btn"
          @click="handleExport"
          >XUẤT EXCEL</b-button
        >
      </b-col>

      <!-- Filter game  -->
      <b-col cols="2">
        <b-form-group
          id="input-game-filter"
          label="Loại Vé"
          label-for="game-filter"
        >
          <b-form-select
            id="game-filter"
            v-model="filter_game"
            :options="gameList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter game  -->
      <b-col cols="2">
        <b-form-group
          id="input-source-filter"
          label="Nguồn Mua"
          label-for="source-filter"
        >
          <b-form-select
            id="source-filter"
            v-model="filter_source"
            :options="sourceList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="2" v-if="isAdmin">
        <b-form-group
          id="input-method-filter"
          label="Nguồn Thanh Toán"
          label-for="method-filter"
        >
          <b-form-select
            id="method-filter"
            v-model="filter_method"
            :options="paymentMethodList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Filter Status -->
      <b-col cols="2">
        <b-form-group
          id="input-status-filter"
          label="Trạng Thái"
          label-for="status-filter"
        >
          <b-form-select
            id="status-filter"
            v-model="filter_status"
            :options="statusList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group
          id="input-group-filter"
          label="Vé mua chung"
          label-for="group-filter"
        >
          <b-form-checkbox
            size="lg"
            id="checkbox-1"
            v-model="is_group"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            {{ is_group ? "Chỉ hiện vé mua chung" : "Tất cả" }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <div class="col-md-4">
        <b-card class="border-primary">
          <h5 class="text-primary">TỔNG VÉ</h5>
          <h1 class="text-primary">
            {{ formatPrice(summary.total_ticket || 0, '') }}
          </h1>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card class="border-success">
          <h5 class="text-success">TỔNG TIỀN</h5>
          <h1 class="text-success">
            {{ formatPrice(summary.total_revenue || 0) }}
          </h1>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card class="border-warning">
          <h5 class="text-warning">TỔNG HOA HỒNG</h5>
          <h1 class="text-warning">
            {{ formatPrice(summary.total_commission || 0) }}
          </h1>
        </b-card>
      </div>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      small
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(created_at)="item">{{
        formatDate(item.value, "YYYY-MM-DD HH:mm:ss")
      }}</template>
      <template v-slot:cell(username)="item">
        {{ item.item.username }}<br />
        <strong>{{ item.item.name }}</strong>
      </template>
      <template v-slot:cell(source)="item">
        {{ item.value ? item.value.toUpperCase() : 'TAILOC' }}
      </template>
      <template v-slot:cell(revenue)="item">
        {{ formatPrice(item.value) }}
      </template>
      <template v-slot:cell(rate)="item">
        {{ formatPrice(item.value * 100, '%') }}
      </template>
      <template v-slot:cell(commission)="item">
        {{ formatPrice(item.value) }}
      </template>
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success"
          >Đã In</b-badge
        >
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning"
          >Chờ Xử Lý</b-badge
        >
        <b-badge class="mr-1" v-else variant="danger">Đã Huỷ</b-badge>
      </template>
    </b-table>
    <b-row>
      <b-col cols="3">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Số dòng: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import ApiService from '@/core/services/api.service.js';

// import axios from "axios";
export default {
  name: 'Ticket',
  components: { datePicker },
  data() {
    return {
      isLoading: true,
      isFilterByGame: true,
      search: '',
      isBusy: false,
      is_group: undefined,
      statusList: [
        { value: 1, text: 'Đã In', variant: 'success' },
        { value: 2, text: 'Đã Huỷ', variant: 'danger' },
        { value: 0, text: 'Chờ Xác Nhận', variant: 'warning' }
      ],
      gameList: [],
      paymentMethodList: [],
      sourceList: [
        { value: 'tailoc', text: 'TAILOC' },
        { value: 'wescan', text: 'WESCAN' },
        { value: 'divine', text: 'DIVINE' }
      ],
      headers: [
        { label: 'Ngày Mua', key: 'created_at' },
        { label: 'Mã Đơn Hàng', key: 'display_order_id' },
        { label: 'Mã Vé', key: 'display_ticket_id' },
        { label: 'Tài Khoản', key: 'username' },
        { label: 'Mã Giới Thiệu', key: 'invite_code' },
        { label: 'Giới Thiệu Bởi', key: 'root_invite_code' },
        { label: 'Vé Mua', key: 'game_name' },
        { label: 'Tiền Mua', key: 'revenue' },
        { label: 'Nguồn Mua', key: 'source' },
        { label: 'Nguồn Thanh Toán', key: 'method_name' },
        { label: 'Tỉ Lệ Hoa Hồng', key: 'rate' },
        { label: 'Tiền Hoa Hồng', key: 'commission' },
        { label: 'Trạng Thái', key: 'status' }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      dialog: false,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      timeout: null,
      filter_from: '',
      filter_to: '',
      username: '',
      invite_code: '',
      root_inviter_code: '',
      filter_game: null,
      filter_method: null,
      filter_source: null,
      filter_status: null,
      summary: {}
    };
  },
  created() {
    this.fetchData();
    this.getAllGame();
    this.getAllPaymentMethod();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Đơn hàng' }]);
  },
  computed: {
    isAdmin() {
      return !this.$store.state.auth?.user?.data?.campaign;
    },
    partnerCampainId() {
      return this.$store.state.auth?.user?.data?.campaign_id;
    },
    campaignId() {
      return this.$store.state.auth?.campaignId;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    campaignId() {
      this.fetchData();
    }
  },
  methods: {
    handleExport() {
      this.exportData('/partners/exports/tickets', this.getQueryParams());
    },
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    getQueryParams() {
      let to = '';
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        limit: this.perPage,
        offset: this.currentPage,
        from: this.filter_from ? new Date(this.filter_from) : '',
        to,
        invite_code: this.invite_code,
        root: this.root_inviter_code,
        game: this.filter_game == null ? -1 : this.filter_game,
        status: this.filter_status == null ? -1 : this.filter_status,
        source: this.filter_source == null ? '' : this.filter_source
      };
      if (this.is_group == 1) {
        returnObj.is_group = 1;
      }

      if (this.isAdmin) {
        returnObj.username = this.username || '';
        returnObj.method = this.filter_method == null ? -1 : this.filter_method;
      }
      return returnObj;
    },
    fetchData() {
      ApiService.query("/partners/tickets", this.getQueryParams()).then(
        (response) => {
          this.items = response.data.data.ticketList;
          this.summary = response.data.data.summary || {};
          this.rows = response.data.data.total;
          this.isBusy = false;
        }
      );
    },
    getAllGame() {
      ApiService.get('allgame').then((response) => {
        this.gameList = response.data.data;
      });
    },
    getAllPaymentMethod() {
      ApiService.get('allpayment').then((response) => {
        this.paymentMethodList = response.data.data.filter(
          (method) =>
            method.allow_transaction ||
            ["vnpay-qr", "divine"].includes(method.handler)
        );
      });
    }
  }
};
</script>
